import React from 'react';
import { useTypewriter} from 'react-simple-typewriter'

const TypeWritter = () => {
    const i = 0;
    const {text} = useTypewriter({
        words: ['Developer', 'Web Designer', 'System Administrator'],
        loop: {i},
        typeSpeed : 120,
        deleteSpeed: 100,
        onLoopDone: () => console.log(`loop completed after 3 runs.`),
    })

    return (
        <>
        <h2><span>&#60; {text}</span><span className={"blue"}> / &#62;</span></h2>
        </>
    )

};

export default TypeWritter;