import React from 'react';
import './App.css';
import './css/index.css'
import Home from './components/Home';
import Diplomas from "./components/Diplomas";
import Experiences from "./components/Experiences";
import Contact from "./components/Contact";
import Github from "./components/Github";
import Project from "./components/Project";

function App() {
  return (
      <>
        <Home/>
        <Project/>
        <Diplomas/>
        <Experiences/>
        <Github/>
        <Contact/>
      </>
  );
}

export default App;
