import React from 'react';
import ssh from '../assets/icon/ssh.svg'
import dev from '../assets/icon/dev.svg'
import train from '../assets/icon/train.svg'
import repair from '../assets/icon/repair.svg'
import school from '../assets/icon/school.svg'
//rsc


const delay = 8000;


const Experiences = () => {

    const data = [
        {
            title: "Self-employed",
            icon: ssh,
            place : "Remote",
            date: "Since March 2021",
            description: "Maintenance of computer servers using SSH and verification of daily backups."
        },
        {
            title: "Self-employed",
            icon: dev,
            place :"Remote",
            date: "Since February 2022",
            description: "Web Development on Maraude App developed by GM CRD."
        },


        {
            title: "Internship",
            icon: train,
            place :"SNCF Technicentre, Chatillon",
            date: "1 week in June 2017",
            description: "I learned about logistic and TGV maintenance."
        },
        {
            title: "Internship",
            icon: repair,
            place :"Garage \"Mega Pneus\", Cliousclat",
            date: "1 week in June 2018",
            description: "I worked in a car garage. I learned about car repair."
        },

        {
            title: "Internship",
            icon: school,
            place :"Middle School \"Saint-Marc\", Lyon",
            date: "1 week in June 2018",
            description: "I did physical education and sports for students. I learn supervision of classes and preparation of activities."
        }

    ]
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }





    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === data.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };

    }, [index]);


    return (
        <div className="more">
            <div className={"head"}>
                <h1>Experiences</h1>
            </div>
            <div className={"body"}>

                <div className="slideshow">
                    <div
                        className="slideshowSlider"
                        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                    >
                        {data.map((val, index) => (
                            <>
                                <div className="slide" key={index}
                                     onClick={() => {
                                         setIndex(index);
                                     }}
                                >
                                    <div className={"head"}>
                                        <img src={val.icon}/>
                                        <h2>{val.title}</h2>
                                    </div>
                                    <p><strong>{val.place}</strong></p>
                                    <p>{val.date}</p>
                                    <p>{val.description}</p>
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="slideshowDots">
                        {data.map((_, idx) => (
                            <div
                                key={idx}
                                className={`slideshowDot${index === idx ? " active" : ""}`}
                                onClick={() => {
                                    setIndex(idx);
                                }}
                            />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Experiences;