import React, {useEffect, useState} from 'react';
import data from "../assets/json/project.json"
import filter from "../assets/icon/filter.svg"
import cancelImg from "../assets/icon/cancel.svg"

const Project = () => {
    const [filteredProject, setFilteredProject] = useState(null);
    const [tags, setTags] = useState([]);
    const [visible, setVisible] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [project, setProject] = useState(false);
    let transitive = [];
    let transTag = [];
    let update = false;
    useEffect(() =>{
        setFilteredProject(data);
    }, [])

    const filterTag = [];
    if(filterTag !== []){
        for (let i = 0; i < data.length; i++){
            for (let j = 0; j < data[i].tag.length; j++){
                if(!filterTag.includes(data[i].tag[j]))
                    filterTag.push(data[i].tag[j]);
            }
        }
    }

    function hideProject(){
        setProject(true);
    }

    function handleProject(e) {
        const tagProject = e.target.value;
        transTag = tags;
        if(tagProject === undefined){
            cancelFilter();
            setTags([]);
        }
        else{
            if(transTag.includes(tagProject)){
                transTag = transTag.filter(e => e !== tagProject);
            }
            else{
                transTag.push(tagProject);
            }
            updateFilter(transTag);
            setTags(transTag);
        }
    }

    function cancelFilter(){
        setVisible(false);
        setCancel(false);
        setFilteredProject(data);
    }

    function updateFilter(tag){
        for (let j = 0; j < data.length; j ++){
            if(tag.every(r => data[j].tag.includes(r))){
                if (!transitive.includes(data[j])){
                    transitive.push(data[j]);
                    update = true;
                }
            }
        }
        if(update){
            if (tag.length === 0){
                setVisible(false);
                setCancel(false);
                setFilteredProject(data);
            }
            else {
                setFilteredProject(transitive);
                setVisible(true);
                setCancel(true);
            }
        }
        else {
            setVisible(false);
            setCancel(true);
            setFilteredProject([{'name' : 'Not found'}]);
        }

    }


    return (
        <div className={"project"}>
            <h1>Projects</h1>
            {project ? (<>
            <div className={"filter-container"}>
                <img src={filter} alt={"Filter"}/>
                <p>:</p>
                {filterTag.map((tag)=> {
                    return(
                        <button className={tags.includes(tag) ? "tag tag-blue tag-cross" : "tag"} value={tag} onClick={handleProject}>{tag}{tags.includes(tag) ? " X" : ""}</button>
                    )})
                }
                {cancel ? <button className={"cancel"} value={"Cancel"} onClick={handleProject}><img src={cancelImg} alt={"Cancel"}/></button> : <></>}
            </div>
            <div className={"project-container"}>
                {filteredProject && filteredProject.map((val)=> {
                    const tagList = val.tag;
                    return (
                        <a href={val.link} target={"_blank"}>
                        <div className={"card"}>
                                <h3>{val.name}</h3>
                                <p>{val.description}</p>
                            <div className={ visible ? "card-footer" : ""}>
                                {visible && tagList.map((value) => {
                                    return(
                                        <button className={tags.includes(value) ? "card-btn btn-blue" : "card-btn"} value={value} onClick={handleProject}>{value}</button>
                                )})}
                            </div>
                        </div>
                        </a>
                    );
                })}
            </div>
                </>):
                <button onClick={hideProject} className={"card hide-project"}><h3>View my projects</h3></button>}
        </div>
    );
};

export default Project;