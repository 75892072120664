import React from 'react';
import '../css/index.css';
import bac from '../assets/icon/bac.svg';
import voltaire from '../assets/icon/voltaire.svg';
import toeic from '../assets/icon/toeic.svg';
import pse from "../assets/icon/pse.svg";
import car from "../assets/icon/car.svg";
import moto from "../assets/icon/moto.svg";
import boat from "../assets/icon/boat.svg"
import crr from "../assets/icon/crr.svg"

const Diplomas = () => {

    return (
        <div className={"diplomas"}>
            <div className={"head"}>
                <h1>Diplomas</h1>
            </div>
            <div className={"body"}>

                <div className={"list"}><img src={bac} alt={"bac"}/><p>Scientific Baccalaureate (2020) <br/> Saint-Thomas-de-
                    Villeneuve high school in Chaville</p></div>
                <div className={"list"}><img src={voltaire} alt={"voltaire"}/><p>Score of 627 (professional spelling) at the Voltaire
                    certificate</p></div>
                <div className={"list"}><img src={toeic} alt={"toiec"}/><p>Score of 810 (B2) at TOEIC certificate</p></div>
                <div className={"list"}><img src={pse} alt={"pse"}/><p>PSE1 and PSE2 (2021)</p></div>
                <div className={"list"}><img src={car} alt={"car"}/><p>Driving license B</p></div>
                <div className={"list"}><img src={moto} alt={"moto"}/><p>Diving license A2</p></div>
                <div className={"list"}><img src={boat} alt={"boat"}/><p>Costal and offshore license</p></div>
                <div className={"list"}><img src={crr} alt={"crr"}/><p>Restricted Radio Telephony Certificate</p></div>

            </div>

        </div>
    );
};

export default Diplomas;