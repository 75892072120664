import React from "react";
import GitHubCalendar from "github-calendar";
import {ReactComponent as GitHubLogo} from "../assets/icon/gh_blue.svg"

const Github = () => {

    React.useEffect(() => {
        GitHubCalendar(".calendar", "pflammarion", { responsive: true });
    },[]);

    return (
        <div className={"github"}>
            <div className={"header"}>
                <h1>GitHub</h1>
                <a href={"https://github.com/pflammarion"}>
                   <GitHubLogo/>
                </a>
            </div>
            <div className="calendar"></div>
        </div>

    );
}

export default Github;